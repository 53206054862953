import { combineReducers, createStore as reduxCreateStore } from "redux";
import { css } from "@emotion/react";

const authorReducer = (state) => {
    state = {
        author: "KinakoCake / Eito Kochi",
    };
    return state;
};

const viewMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SHOW_MENU":
            return {
                isViewMenu: true,
            };
        case "HIDE_MENU":
            return {
                isViewMenu: false,
            };
        default:
            return state;
    }
};

const menuImageReducer = (state = initialState, action) => {
    switch (action.type) {
        case "IMAGE_DISPLAY":
            if (action.payload[0] == "SHOW") {
                const menuImageCSS = [
                    css`
                        display: block !important;
                    `,
                    action.payload[1],
                ];
                return {
                    menuImage: menuImageCSS,
                };
            } else if (action.payload[0] == "HIDE") {
                const menuImageCSS = [
                    css`
                        display: none !important;
                    `,
                    action.payload[1],
                ];
                return {
                    menuImage: menuImageCSS,
                };
            } else {
                console.error(
                    "[menuImageReducer] Unexpected action.payload[0]"
                );
                return;
            }
        default:
            return state;
    }
};

const recentlyPrevWorkImageDisplayReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_DISPLAY_PREV":
            if (action.payload == "view") {
                return {
                    recentlyPrevWorkImageDisplay: css`
                        display: block !important;
                    `,
                };
            } else if (action.payload == "hide") {
                return {
                    recentlyPrevWorkImageDisplay: css`
                        display: none !important;
                    `,
                };
            } else {
                return {
                    recentlyPrevWorkImageDisplay: css`
                        display: none !important;
                    `,
                };
            }
        default:
            return state;
    }
};

const recentlyNextWorkImageDisplayReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_DISPLAY_NEXT":
            if (action.payload == "view") {
                return {
                    recentlyNextWorkImageDisplay: css`
                        display: block !important;
                    `,
                };
            } else if (action.payload == "hide") {
                return {
                    recentlyNextWorkImageDisplay: css`
                        display: none !important;
                    `,
                };
            } else {
                return {
                    recentlyNextWorkImageDisplay: css`
                        display: none !important;
                    `,
                };
            }
        default:
            return state;
    }
};

const seoDescReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_SEO_DESC":
            return {
                seoDesc: action.payload,
            };
        default:
            return state;
    }
};

const WorkComponentLoadedReducer = (state = initialState, action)=>{
    switch(action.type){
        case "TRUE_HAS_WORK_COMPONENT_LOADED":
            return {
                hasWorkComponentLoaded: true,
            };
        case "FALSE_HAS_WORK_COMPONENT_LOADED":
            return {
                hasWorkComponentLoaded: false,
            };
        default:
            return state;
    }
}



const initialState = {
    isViewMenu: false,
    data: "null",
    recentlyPrevWorkImageDisplay: css`
        display: none !important;
    `,
    recentlyNextWorkImageDisplay: css`
        display: none !important;
    `,
    menuImage: [
        css`
            display: none !important;
        `,
        null,
    ],
    seoDesc: "hogehogepiyo",
    hasWorkComponentLoaded: false,
};

const rootReducer = combineReducers({
    authorReducer,
    viewMenuReducer,
    recentlyNextWorkImageDisplayReducer,
    recentlyPrevWorkImageDisplayReducer,
    menuImageReducer,
    seoDescReducer,
    WorkComponentLoadedReducer,
});

const createStore = () => reduxCreateStore(rootReducer, initialState);
export default createStore;
