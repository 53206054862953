module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"kinakocake.net","short_name":"kinakocake.net","start_url":"/","background_color":"#FDF9F9","theme_color":"#FDF9F9","display":"browser","crossOrigin":"use-credentials","icon":"src/images/favicon.png","legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"a3e32278d7ab3e21a03fcaa6874f92e9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://kinakocake.net","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
